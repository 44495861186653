import { useMatches } from "@remix-run/react";

import type { ValidatedUser } from "@wind/auth";

import type { AdminPermissionType } from "~/config/adminPermissions.js";

export const useOptionalAuthUser = () => {
  const matches = useMatches();

  const rootPath = matches.find((match) => match.pathname === "/");

  const data = rootPath?.data as
    | { authUser: ValidatedUser<AdminPermissionType> | null }
    | undefined;
  return data?.authUser as ValidatedUser<AdminPermissionType>;
};

export const useAuthUser = () => {
  const user = useOptionalAuthUser();
  if (!user) {
    throw new Response("No auth user found", {
      status: 500,
    });
  }
  return user;
};
